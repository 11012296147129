import React, { useState, useEffect } from "react";
import { EncircledCrossExit, UploadIcon } from "../../assets/SVG/svg";
import "./invoice.scss";
import styles from "./invoice.scss";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createBatch, invoiceUpload, getInvoice } from "../../services/api";
import { allowedFileTypes } from "../../utils/helper";
import SecondaryButton from "../../components/common/Button/ButtonSecondary";
import DataResults from "../dataResults/dataResults";
import ProgressBarCustom from "../../components/common/ProgressBar/progressbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Notification } from "../../utils/Notification";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";
import { setApiCount } from "../../ReduxSlices/ApiCountSlice";
import { SERVICES } from "../../utils/ConstantSettings";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPlan, setPlanPaidStatus, setPlanSubStatus, setPlanValidity, setCurrentPlanId  } from "../../ReduxSlices/CurrentPlanSlice";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";


const InvoiceScreen = () => {
  const { trackEvent } = useTracking({ component: 'Process Module' });
  const {t} = useTranslation()

  const { id, cid } = useParams();
  const [firstFileUploaded, setFirstFileUploaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [batch, setBatch] = useState();
  const newBatch = useMutation(createBatch);
  const addInvoice = useMutation(invoiceUpload);
  const [dataResults, setDataResults] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [view, setView] = useState(false);
  const [edittable, setEdittable] = useState(true);
  const [notification, setNotification] = useState({
    open: false,
    variant: "",
    message: "",
  });
  const setNotifications = () => {
    setNotification({ openn: false, variant: "", message: "" });
  };

  const apiCounter = useSelector((state)=>state.apiCount)
  const questioner_filled = useSelector((state)=>state.questioner_filled)

    // Enterprise modal pop up:
    const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);
    const handleShowEnterpriseModal = () => setShowEnterpriseModal(true);
    const handleCloseEnterpriseModal = () => setShowEnterpriseModal(false);

    
function discoverSimplaSendEmail(sendOption="email"){

  setShowEnterpriseModal(true)

  if (localStorage.getItem("simpla_enterprise_email_sent") == "false"){
      axios.post(`${SERVICES.apiBaseUrl}/api_usage/createPremiumRequest`, {notification_method: `${sendOption}`}, 
        {headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json; charset=UTF-8',

        }})
        .then((res)=>{
          let status;
          try {
            status = JSON.parse(res.data?.object)?.status
            localStorage.setItem("simpla_enterprise_email_sent", status )
          }
          catch(error){
            localStorage.setItem("simpla_enterprise_email_sent", true )
            trackEvent({
              event_type: "error", 
              event_name:"Failed to get/parse get-in-touch email status (response of API was not parsed)", 
              event_data:{
                error: `${error}`
              }
            })
          }

          if (res.data?.message === "Success" || res.data?.message === "success"){
            localStorage.setItem("simpla_enterprise_email_sent", true )
          }
        })
        .catch((error)=>{
          trackEvent({
            event_type: "error", 
            event_name:"Failed to send get-in-touch email request", 
            event_data:{
              error: `${error}`
            }
          })
        })
  }

  
}

  useEffect(() => {
    if (cid) {
      setDataResults(true);
    } else {
      setDataResults(false);
    }
  }, [cid]);
  const createBatchCall = (vall, ind) => {
    if (ind === true) {
      newBatch.mutate(
        {
          name: "Batch",
          profile_id: localStorage.getItem("profileid"),
        },
        {
          onSuccess: (data, variables, context) => {
            setBatch(data?.data?.object?.id);
            for (const file of vall) {
              const formData = new FormData();
              formData.append("file", file);
              addInvoiceCall(formData, data?.data?.object?.id);
            }
          },
          onError: (error, variables, context) => {
           
          },
        }
      );
    }
  };
  const { isFetching } = useQuery(
    ["invoices", localStorage.getItem("profileid"), cid],
    getInvoice,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: cid !== undefined,
      onSuccess: (data) => {
        setTableData(data?.data?.object?.data);
        if (data?.data?.object?.batch?.is_processed === false) {
          setView(false);
        } else {
          setView(true);
        }
        if (data?.data?.object?.batch?.submit_status === "NotSubmitted") {
          setEdittable(true);
        } else {
          setEdittable(false);
        }
        setBatch(data?.data?.object?.batch?.id);
      },
    }
  );

  

  const headers = new Headers({
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json; charset=UTF-8'

  });

  const dispatch = useDispatch()

      // want to use more of the module modal pop up:
      const [showModal, setShowModal] = useState(false);
      const handleModalShow = () => setShowModal(true);
      const handleModalClose = () => setShowModal(false);

      function dialogContentText(){
        if (questioner_filled == true){
          return `${t("form_already_filled")}`
        }
      
        else if (questioner_filled == false){
          return `${t("enjoying_simpla")}`
        }
        // else if (apiCounter > 0 && questioner_filled == false){
        //   return `${t("unexhauted_uses_left")}`
        // }
      }
    
      
      const uselocation = useLocation()
      
      const navigate = useNavigate()
      
      const navigateToSurvey = () => {
        if (apiCounter == 0 && questioner_filled == false){
        
          // Access current pathname, search, and hash
          const { pathname, hash } = uselocation;
      
          // Full current URL
      
          
          let currentURL = "/"
          
          if(!`${pathname}${hash}`.includes("survey")){
            currentURL = `${pathname}${hash}`
          }
          
          console.log('Current URL:', currentURL);
      
          localStorage.setItem("currentURL", currentURL)
      
          // navigate to survey
          //window.location.replace(`${window.location.origin.toString()}/survey`);
          navigate("/survey")
        }
      };
      

  async function fetchApiCount() {
    try {
        const response = await fetch(`${SERVICES.apiBaseUrl}/api_usage/getApiCount`, {
            headers,
            method: "GET",
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const apiCountData = await response.json();
        //console.log("api count data =", apiCountData);

        // Assuming setApiCount is a function to handle the API count data
        dispatch(setApiCount(`${apiCountData?.object?.remaining_credits}`))
                dispatch(setCurrentPlan(`${apiCountData?.object?.plan_name}`))
        dispatch(setCurrentPlanId(`${apiCountData?.object?.id}`))
        dispatch(setPlanPaidStatus(`${apiCountData?.object?.paid_plan}`))
        dispatch(setPlanSubStatus(`${apiCountData?.object?.is_subscribed}`))
        dispatch(setPlanValidity(`${apiCountData?.object?.valid_date}`))
        dispatch(setCurrentPlanId(`${apiCountData?.object?.id}`))
        localStorage.setItem("apiCounter", `${apiCountData?.object?.remaining_credits}`)
    } catch (error) {
        console.error("Error fetching API count:", error);
        trackEvent({
          event_type: "error", 
          event_name:"Failed to fetch Api Counts (Remaining Simpla Uses)", 
          event_data:{
            error: `${error}`
          }
        })
    }
}

  const addInvoiceCall = async (file, batch) => {
    try {
      const response = await addInvoice.mutateAsync({
        file: file,
        profile: localStorage.getItem("profileid"),
        batch: batch,
      });

      const newData = response?.data?.object;

      setTableData((prevDataArray) => [...prevDataArray, newData]);
      setSelectedFile((prevSelectedFiles) => {
        const updatedSelectedFiles = prevSelectedFiles.map((progressEntry) => {
          if (progressEntry.file === file.get("file")) {
            return { ...progressEntry, progress: 100 };
          }
          return progressEntry;
        });
        return updatedSelectedFiles;
      });

      setTimeout(()=>{
      //fetchApiCount()
      }, 2000)

    } catch (error) {
      trackEvent({
        event_type: "error", 
        event_name:"An Error Occured in Invoice File Upload", 
        event_data:{
          error: `${error}`
        }
      })
      setSelectedFile((prevSelectedFiles) => {
        const updatedSelectedFiles = prevSelectedFiles.map((progressEntry) => {
          if (progressEntry.file === file.get("file")) {
            return { ...progressEntry, progress: 0 };
          }
          return progressEntry;
        });
        return updatedSelectedFiles;
      });
      setNotification({
        open: true,
        variant: "danger",
        message: "Unable to upload, please check file type or file size",
      });
    }
  };
  const resetState = () => {
    setFirstFileUploaded(false);
    setSelectedFile([]);
    setBatch();
    setDataResults(false);
    setTableData([]);
  };

  const UploadHandler = (event) => {
    const selectedFiles = event.target.files;
    let isFirstFile = true;
    const newProgressEntries = []; // Accumulate progress entries for all files

    for (const file of selectedFiles) {
      if (!allowedFileTypes?.includes(file.type)) {
        alert(
          "Invalid file type. Please select a valid document or spreadsheet file."
        );
      } else {
        const formData = new FormData();
        formData.append("file", file);

        if (!firstFileUploaded) {
          createBatchCall(selectedFiles, isFirstFile);
          setFirstFileUploaded(true);
          isFirstFile = false;
        } else {
          addInvoiceCall(formData, batch);
        }
        const newProgressEntry = { file: file, progress: 75 };
        trackEvent({event_type: "click", event_name:"file upload", event_data: {action:"file uploaded in Process Module", filename:`${newProgressEntry.file.name}`}})

        newProgressEntries.push(newProgressEntry); // Accumulate progress entry
      }
    }
    setSelectedFile([...selectedFile, ...newProgressEntries]); // Update state after the loop
  };
  return (
    <>
      {!dataResults ? (
        <div className="invoice-wrapper">
          <div className="invoice-container invoice-module-container upload-container-files">
            <div className="invoice-container-top">
              <h2>{t("invoice_upload1")}</h2>
              <span style={{direction:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "rtl" : "ltr"}`}}> {t("file_upload_type")}</span>
            </div>
            
            <div 
                className="upload-wrapper" >
              {
                // apiCounter === "0" ? 
                // <div style={{width:"100%", height:"228px", position:"absolute",}} onClick={()=>{
                //   if (apiCounter === "0") { 
                //     handleModalShow()}
                //     }}></div> : 
                <>
              <input
                // disabled={apiCounter == "0" ? true : false}
                type="file"
                id="file"
                className="file-Input"
                multiple
                onChange={UploadHandler}
                accept=".txt, .rtf, .doc, .docx, .odt, .pdf, .xls, .xlsx, .ods, .csv, .jpeg"
              />                
                </>
              }

              <UploadIcon />
              <div className="upload-wrapper-text">
                <span className="upload-wrapper-text-browser"Click>{t("invoice_upload2")}</span> 
                <span className="upload-wrapper-text-dragDrop">
                {t("invoice_upload_body")}</span>
              </div>
            </div>
            <ul id="fileList" className="Upload-file-wrapper">
              {selectedFile?.map((val, index) => (
                <li className="list" key={index}>
                  <div className="list-inner-box">
                    <div className="list-inner-box-title">
                      <div className="img-name-wrapper">
                        <span className="file-icon pdf-file"></span>
                        <span>{val?.file?.name}</span>
                      </div>
                      {val?.progress === 100 && (
                        <div className="uploaded upload-common "></div>
                      )}

                      {val?.progress === 0 && (
                        <div className="cross-upload upload-common"></div>
                      )}
                    </div>
                    <ProgressBarCustom progress={val?.progress} />
                  </div>
                </li>
              ))}
            </ul>
            {firstFileUploaded && (
              <SecondaryButton
                buttonText={`${t("next")}`}
                styleclass={"btn-process next-btn"}
                icon={undefined}
                hasIcon={false}
                size={undefined}
                type="submit"
                disabled={addInvoice?.isLoading || selectedFile.length < 1}
                onClick={() => setDataResults(true)}
              />
            )}
          </div>
        </div>
      ) : (
        <DataResults
          tableData={tableData}
          onBack={() => setDataResults(false)}
          batch={batch}
          resetState={resetState}
          view={view}
          edittable={edittable}
          loading={isFetching}
        />
      )}
       {notification.open && (
        <Notification
          open={notification.open}
          variant={notification.variant}
          message={notification.message}
          setNotifications={setNotifications}
        />
      )}

            {/* uses exhausted dialog modal */}
            <Dialog
  open={showModal}
  onClose={handleModalClose}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "285px",
      borderRadius: "15px",
      background: "#fdfbf5",

    }
  }}
>
  <DialogTitle>


    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
      <div className="" 
         style={{ 
            position: 'absolute', 
            top: "1.5%", 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "1.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "1.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>

      <div 
          onClick={() => setShowModal(false)} 
          style={{ marginTop: "3px", cursor: 'pointer', }} >
        <EncircledCrossExit/>        
       </div>

      </div>
  </DialogTitle>
  <DialogContent>

    <div style={{padding:"4% 2%"}}>
    <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("want_more_btn")}
    </h6>
    <p style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"25px", paddingTop:"1%"}}>
      {dialogContentText()}
    </p>    
    </div>

  </DialogContent>
  <DialogActions style={{padding:"0% 2% 7% 2%", margin: "0% 3%", gap:"10px"}}>
  {/* <div onClick={navigateToSurvey} className={`more-uses-btn more-uses-dialog-btn orange-bg-btn-transition`} style={{  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }}>
      <Typography variant="p" className={`more-uses-btn-text orange-bg-btn-text`} style={{fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("get_more_uses")}</Typography>
    </div> */}

<div onClick={navigateToSurvey} className={`more-uses-btn more-uses-dialog-btn`} style={{  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }}>
      <Typography variant="p" className={`more-uses-btn-text`} style={{fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("get_more_uses")}</Typography>
    </div>

    <div className="more-uses-btn more-uses-dialog-btn" style={{ borderColor: `black`,  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }} onClick={()=>{ setShowModal(false); discoverSimplaSendEmail()}}>
      <Typography variant="p" className="more-uses-btn-text" style={{color: `black`, fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("simpla_enterprise")}</Typography>
    </div>
  </DialogActions>
</Dialog>


{/* We will be in touch modal */}
<Dialog
  open={showEnterpriseModal}
  onClose={handleCloseEnterpriseModal}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "max-content",
      borderRadius: "15px",
      background: "#fdfbf5",


    }
  }}
>
  <DialogTitle>


    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
    <div className="close-circle" 
        style={{ 
            position: 'absolute', 
            top: '11.5%', 
            right: localStorage.getItem('i18nextLng') === "ar" ? 'auto' : '2%', 
            left: localStorage.getItem('i18nextLng') === "ar" ? '2%' : 'auto', 
            transform: 'translateY(-50%)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            cursor: 'pointer' 
        }}>
        <CloseIcon 
            fontSize="normal" 
            onClick={() => setShowEnterpriseModal(false)} 
            style={{ margin: 0 }} 
        />
       </div>
  </DialogTitle>

  <DialogContent>

    <div style={{padding:"5%"}}>

    <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("in_touch_modal")}
    </h6>

    <p style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"20px" }}>
      {t("in_touch_modal_description")}
    </p>

    </div>


  </DialogContent>


</Dialog>
    </>
  );
};
export default InvoiceScreen;
