
import { Navigate,Outlet, useNavigate } from "react-router-dom";
export const AuthenticatedRoutes = ({
    children,
  }) => {


   const token=((localStorage?.getItem('token')));
   const profile=((localStorage?.getItem('profile')));
   const navigate = useNavigate()

    if (!token) {

    
      return <Navigate to="/login" replace />;
    }
    else if(token&&(profile==='false')){
      return <Navigate to="/accountsetup" replace />;
      // return <Navigate to="/paymentplan" replace />;
      // return navigate("/paymentplan")

    }
  
    return children ? children : <Outlet />;
  };