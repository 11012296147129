import { Navigate } from "react-router-dom"
import EmiratesSimplaLogo from "../../../assets/SVG/EmiratesSimplaLogo.svg"
import "./SimplaBranding.css"

const BrandUrl = "https://simpla.ai/"

function onLogoNavigate(){
  //onLogoNavigate()
  return <Navigate to="/" replace/>
}
// non-edited branding logo
const BrandingLogo = () => (
  <div onClick={onLogoNavigate}>
    <img style={{ width: "155px", height: "80px", cursor: "pointer" }} src={EmiratesSimplaLogo} />
  </div>
)


// branding logo customized for desktop view
const DesktopBranding = () => {

    return(<>
            <div className={`main-simpla-logo ${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "main-simpla-logo-ar" : "main-simpla-logo-en"}`} 
            onClick={onLogoNavigate}
            >
              <img style={{width: "155px", height:"80px"}} src={EmiratesSimplaLogo} />
            </div>
    </>)
}

// branding logo customized for mobile view
const MobileBranding = () => {

  return (<>
    {/* SVG Logo */}
    <div className="mobile-simpla-logo"
      style={{
        zIndex: 20, 
        marginRight: localStorage.getItem('i18nextLng') === "ar" ? "10px" : "",
        width: "fit-content"
      }}
      onClick={onLogoNavigate}
      >
      <img style={{ width: "135px", height: "72px" }} src={EmiratesSimplaLogo} />
    </div>
  </>)
}

export { BrandingLogo, MobileBranding, DesktopBranding, BrandUrl }