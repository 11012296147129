import React, { useState } from "react";
import "../VerticalTabs/verticaltabs.scss";
import { useNavigate, Link } from "react-router-dom";
import { ChatIcon, CommentIcon, ProductTourIcon, VedioIcon } from "../../assets/SVG/svg";
import ModalVedio from "../ModalVedio";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";
const VerticalTabsHelp = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const {t} = useTranslation()

  const {trackEvent} = useTracking()

  return (
    <>
      <div className="TabBox">
        {/* <Link className="nav-btn vediobtn tooltip" 
        onClick={()=>{    
            trackEvent({event_type: "click", event_name: `user accessed video tutorial from Help Tab`, event_data: {video_link: "https://www.youtube.com/@SimplaAI/videos"} })
            window.open('https://www.youtube.com/@SimplaAI/videos')}}>
          <div className="tooltipTextWrapper">
            <span class="tooltiptext">{t("tutorial")}</span>
          </div>
          <VedioIcon />
        </Link>
        {show ? <ModalVedio show={show} handleClose={handleClose} /> : null}
        <a
          className="nav-btn chatbtn tooltip"
          href="https://www.simpla.ai/faqs/"
          target="_blank"
          onClick={()=>{
            trackEvent({event_type: "click", event_name: `user clicked on FAQs in Help Tab`, event_data: {faq_link: "https://www.simpla.ai/faqs"} })
          }}
        >
          <div className="tooltipTextWrapper">
            <span class="tooltiptext">{t("faqs")}</span>
          </div>
          <ChatIcon />
        </a> */}
        <a className="nav-btn tourbtn tooltip" onClick={props?.productTour} onClickCapture={()=>{trackEvent({event_type: "click", event_name: `user clicked on Product Tour in Help Tab`, event_data: {action: "User took an on-site product tour tutorial from the help tab"} })}}>
          <div className="tooltipTextWrapper">
            <span class="tooltiptext" style={{fontSize:"small"}}>{t("product_tour")}</span>
          </div>
          <ProductTourIcon />
        </a>
      </div>
    </>
  );
};
export default VerticalTabsHelp;
