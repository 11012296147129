import React from "react";
import Form from "react-bootstrap/Form";
const AnalyseFormSelectRounded = ({
  label,
  options,
  value,
  keyVal,
  onChange,
  disabled,
  styleclass,
  text,
  locale
}) => {

  const labelsEnglish = [

    //upload analyse file options
    "Balance Sheet",
    "Profit and Loss Statement",
    "Cash Flow Statement",
    "Raw Data",

    // analyse options
    "Key Perfomance Indicators", 
    "Graph & Visual Presentation",
    "Written Variance/Number Analysis & Investigation Points",

    // Options of "Key Performance Indicators"
    "Liquidity Metrics",
    "Profitability Metrics",
    "Operational Efficiency Metrics",
    "Solvency Metrics", 
    "Market Value Metrics",

    // Options of "Graph & Visual"
    "Bar Chart",
    "Line Chart",
    "Pie Chart",
    "Scatter Plot",
    "Area Chart",

    // Options of "Written Variance/Number Analysis & Investigation Points"
    "Executive Summary",
    "Detailed Analysis",
    "Comparative Analysis",
    "Forecast & Predictions",
    "Recommendations",


    //3rd drop down - common
    "Run All",

    //3rd dropdown options of Liquidity metrics
    "Current Ratio",
    "Quick Ratio (Acid Test)",
    "Cash Ratio",
    "Operating Cash Flow Ratio",
    "Net Working Capital",

    //3rd dropdown options of Profitability metrics
    "Gross Profit Margin",
    "Operating Profit Margin",
    "Return on Equity (ROE)",
    "Return on Assets (ROA)",
    "Earnings Before Interest and Taxes (EBIT) Margin",

    //3rd dropdown options of Operational Efficiency Metrics
    "Inventory Turnover",
    "Asset Turnover",
    "Receivables Turnover",
    "Payable Turnover",
    "Day Sales in Inventory (DSI)",

    //3rd dropdown options of Solvency Metrics
    "Debt-to-Equity Ratio",
    "Total Debt Ratio",
    "Times Interest Earned",
    "Equity Ratio",
    "Financial Leverage Ratio",

    //3rd dropdown options of Market Value Metrics
    "Price-to-Earnings Ratio (P/E)",
    "Market-to-Book Ratio",
    "Dividend Yield",
    "Earnings Yield",
    "Price-to-Sales Ratio (P/S)",

    //-----

    //3rd dropdown options of Bar Chart
    "Revenue vs. Expense",
    "Yearly Sales Comparison",
    "Market Share by Region",
    "Product Line Performance",
    "Employee Productivity",

    //3rd dropdown options of Line Chart
    "Stock Price Over Time",
    "Monthly Revenue Trend",
    "Year-over-Year Growth",
    "Customer Acquisition Trend",
    "Operating Costs Over Time",

    //3rd dropdown options of Pie Chart
    "Revenue Distribution by Product",
    "Expense Breakdown",
    "Customer Demographics",
    "Asset Composition",
    "Sales Channels Contribution",

    //3rd dropdown options of Scatter Plot
    "Price vs. Demand",
    "Advertising Spend vs. Sales",
    "Employee Experience vs. Productivity",
    "Customer Reviews vs. Sales",
    "Operational Costs vs. Revenue",

    //3rd dropdown options of Area Chart
    "Sales Over Time",
    "Market Share Evolution",
    "Revenue vs. Profit Over Time",
    "Customer Segments Growth",
    "Resource Allocation",

    //3rd dropdown options of Executive Summary
    "Overview of Financial Health",
    "Key Achievements",
    "Challenges Faced",
    "Recommendations",
    "Forecast",

    //3rd dropdown options of Detailed Analysis
    "Revenue Streams Breakdown",
    "Expense Analysis",
    "Key Performance Indicators",
    "Market Positioning",
    "Risk Assessment",

    //3rd dropdown options of Comparative Analysis
    "Peer Comparison",
    "Industry Benchmarking",
    "Historical Performance",
    "Market Share Analysis",
    "SWOT Analysis",

    //3rd dropdown options of Forecast & Predictions
    "Revenue Forecast",
    "Expense Prediction",
    "Market Trends",
    "Potential Risks",
    "Opportunities",

    // 3rd dropdown options of Recommendations
    "Operational Efficiency",
    "Market Strategy",
    "Financial Strategy",
    "Product or Service Enhancements",
    "Organizational Structure"






  ]

  const labelsArabic = [

    //upload analyse file options
    " الميزانية",
    "قائمة الأرباح والخسائر",
    "قائمة التدفقات النقدية",
    "البيانات الأصلية",

    // analyse options
    "مؤشرات الأداء الرئيسية",
    "الرسم البياني والعرض المرئي",
    "التباين الكتابي/تحليل الأرقام ونقاط التحقيق",

    // Options of "Key Performance Indicators"
    "مقاييس السيولة",
    "مقاييس الربحية",
    "مقاييس الكفاءة التشغيلية",
    "مقاييس الملاءة المالية",
    "مؤشرات القيمة السوقية",

    // Options of "Graph & Visual"
    "شريط بياني",
    "خط بياني",
    "مخطط دائري",
    "مخطط مبعثر",
    "مخطط مساحي",

    // Options of "Written Variance/Number Analysis & Investigation Points"
    "ملخص تنفيذي",
    "تحليل تفصيلي",
    "تحليل مقارن",
    "التوقعات والتنبؤات",
    "التوصيات",


    //3rd drop down - common
    "توليد الكل",

    //3rd dropdown options of Liquidity metrics
    "النسبة الحالية",
    "النسبة السريعة (اختبار حاسم)",
    "نسبة النقدية",
    "نسبة التدفق النقدي التشغيلي",
    "صافي رأس المال العامل",

    //3rd dropdown options of Profitability metrics
    "هامش الربح الإجمالي",
    "هامش الربح التشغيلي",
    "العائد على حقوق الملكية(ROE)",
    "عائد على الأصول (ROA)",
    "هامش الربح قبل الفوائد والضرائب(EBIT)",

    //3rd dropdown options of Operational Efficiency Metrics
    "معدل دوران المخزون",
    "معدل دوران الأصول",
    "معدل دوران الذمم المدينة",
    "معدل دوران الذمم الدائنة",
    "يوم المبيعات في المخزون (DSI)",

    //3rd dropdown options of Solvency Metrics
    "نسبة الدين إلى حقوق الملكية",
    "نسبة الدين الإجمالية",
    "معدل الفائدة المكتسبة",
    "نسبة حقوق الملكية",
    "نسبة الرافعة المالية",

    //3rd dropdown options of Market Value Metrics
    "نسبة السعر إلى الربح",
    "نسبة السعر السوقي إلى السعر الدفتري",
    "عائد توزيعات الأرباح",
    "عائد الأرباح",
    "نسبة السعر إلى المبيعات(P/S)",

    //--------

    //3rd dropdown options of Bar Chart
    "الإيرادات مقابل المصروفات",
    "مقارنة المبيعات السنوية",
    "حصة السوق حسب المنطقة",
    "أداء خط الإنتاج",
    "إنتاجية الموظفين",

    //3rd dropdown options of Line Chart
    "سعر السهم مع مرور الزمن",
    "اتجاه الإيرادات الشهرية",
    "النمو السنوي",
    "اتجاه اكتساب الزبائن",
    "الكلفة التشغيلية مع مرور الزمن",

    //3rd dropdown options of Pie Chart
    "توزيع الإيرادات حسب المنتج",
    "تفاصيل المصروفات",
    "التوزيع السكاني للزبائن",
    "تركيب الأصول",
    "مساهمة قنوات المبيعات",

    //3rd dropdown options of Scatter Plot
    "السعر مقابل الطلب",
    "المصاريف الإعلانية مقابل المبيعات",
    "تجربة الموظف مقابل الإنتاجية",
    "مراجعات الزبائن مقابل المبيعات",
    "التكاليف التشغيلية مقابل الإيرادات",

    //3rd dropdown options of Area Chart
    "المبيعات مع مرور الزمن",
    "تطور حصة السوق",
    "الإيرادات مقابل الربح مع مرور الزمن",
    "نمو قطاعات العملاء",
    "نمو شرائح الزبائن",

    //3rd dropdown options of Executive Summary
    "نظرة عامة على الصحة المالية",
    "الإنجازات الرئيسية",
    "التحديات التي تواجهها",
    "التوصيات",
    "التوقعات",

    //3rd dropdown options of Detailed Analysis
    "تفاصيل تدفقات الإيرادات",
    "تحليل المصروفات",
    "مؤشرات الأداء الرئيسية",
    "تحديد المواقع في السوق",
    "تقييم المخاطر",

    //3rd dropdown options of Comparative Analysis
    "مقارنة الأقران",
    "اختبار أداء مجال العمل",
    "الأداء التاريخي",
    "تحليل حصة السوق",
    "تحليل نقاط القوة والضعف والفرص والمخاطر",

    //3rd dropdown options of Forecast & Predictions
    "توقعات الإيرادات",
    "التنبؤ بالمصروفات",
    "اتجاهات السوق",
    "المخاطر المحتملة",
    "الفرص",

    // 3rd dropdown options of Recommendations
    "الكفاءة التشغيلية",
    "استراتيجية السوق",
    "الاستراتيجية المالية",
    "تحسين المنتج أو الخدمة",
    "الهيكل التنظيمي",

    //extra labels
    "المقاييس"

  ]



  function matchMaker(option, locale = `${localStorage.getItem("i18nextLng")}`) {
    if (locale === "ar") {
      let index = labelsEnglish.indexOf(option); // Use indexOf instead of findIndex
      // console.log(`
      //   op=${option}
      //   locale=${locale}
      //   index=${index}
      // `);
      if (index !== -1) { // Check if index is found
        return labelsArabic[index];
      } else {
        return option;
      }
    }
    return option;
  }
  

 

  //console.log("AnalyseFormSelectRounded - locale", locale)
  return (<>
    <label className="form-label">{label}</label>
    {/* <Form.Select
      aria-label={label}
      className={`form-select-rounded ${styleclass} `}
      value={disabled ? "" : value}   
      onChange={(e)=>onChange(keyVal,e.target.value,e.target.options[e.target.selectedIndex].getAttribute('data-value'))}
      disabled={disabled}
    >
  {value === ""    && <option value="">{text}</option>}
 
      {options?.map((item) => (
        <option value={item.id} keyy={item.id} disabled={item?.disable}  data-value={item.value} key={item?.id}>
          {item.valueArabic}
        </option>
      ))}
    </Form.Select> */}

<Form.Select
        aria-label={label}
        className={`form-select-rounded ${styleclass} `}
        value={disabled ? "" : value}
        // onChange={(e) => {
        //   const selectedValue = e.target.value;
        //   const selectedOption = locale ===  "ar" ? options.find(option => option.valueArabic === selectedValue) : options.find(option => option.value === selectedValue);
        //   console.log("selectedValue:", selectedValue)
        //   console.log("selectedOption:", selectedOption)

        //   onChange(keyVal, selectedValue, selectedOption?.value);
        // }}
        onChange={(e)=>onChange(keyVal,e.target.value,e.target.options[e.target.selectedIndex].getAttribute('data-value'))}

        disabled={disabled}
      >
        {value === "" && <option value="">{text}</option>}
        {options?.map((item) => (
          <option
            value={item.id}
            keyy={item.id}
            disabled={item?.disable}
            data-value={item.value}
            key={item?.id}
          >
            {/* { locale && locale === "ar" ? item.valueArabic : item.value} */}

            {matchMaker(`${item.value}`, `${locale}`)}

          </option>
        ))}
      </Form.Select>


    
    </>
  );
};
export default AnalyseFormSelectRounded;
