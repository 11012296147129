import { configureStore } from "@reduxjs/toolkit";
import sidebarSlice from "./ReduxSlices/SidebarSlice";
import ChatModeSlice from "./ReduxSlices/ChatModeSlice";
import ApiCountSlice from "./ReduxSlices/ApiCountSlice";
import profileImageSlice from "./ReduxSlices/ProfileImageSlice"
import requestKillerSlice from "./ReduxSlices/RequestKillerSlice";
import profileSettingModalSlice from "./ReduxSlices/ProfileSettingModal";
import AccountSettingSlice from "./ReduxSlices/AccountSettingSlice";

//import  getDefaultMiddleware from '@reduxjs/toolkit';


import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web

import { combineReducers } from "redux";
import RequestActiveStatusTrackerSlice from "./ReduxSlices/RequestActiveStatusTrackerSlice";
import CurrentPlanSlice from "./ReduxSlices/CurrentPlanSlice";
import QuestionerStatusSlice from "./ReduxSlices/QuestionerStatusSlice";
import SignOffCreditsSlice from "./ReduxSlices/SignOffCreditsSlice";
import ProfileCompletionSlice from "./ReduxSlices/ProfileCompletionSlice";
import HistoryRefetchSlice from "./ReduxSlices/HistoryRefetchSlice";

const reducers=combineReducers({
    displaySidebar: sidebarSlice,
    chatMode: ChatModeSlice,
    apiCount: ApiCountSlice,
    profileImage: profileImageSlice,
    killRequest: requestKillerSlice,
    requestActive: RequestActiveStatusTrackerSlice,
    currentPlan: CurrentPlanSlice,
    questioner_filled: QuestionerStatusSlice,
    signOffCredits: SignOffCreditsSlice,
    profileCompletion: ProfileCompletionSlice,
    profileSettingModal: profileSettingModalSlice,
    AccountSetting: AccountSettingSlice,
    historyRefetch: HistoryRefetchSlice

})

// const store = configureStore({
//     reducer: {
//         displaySidebar: sidebarSlice, // counter is name of use-state
//         chatMode: ChatModeSlice,
//         apiCount: ApiCountSlice,
//         profileImage: profileImageSlice
//     }
// })


// Configure persistence for the profileImageSlice
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['profileImage', 'currentPlan'],
  };

//   const customizedMiddleware = getDefaultMiddleware({
//     serializableCheck: false
//   })
  
  const persistedReducer = persistReducer(persistConfig, reducers);
  
  // Create the Redux store
  const store = configureStore({
    reducer: persistedReducer,
  });
  
  // Create the Redux store with persistence
  export const persistor = persistStore(store);

//export type RootState = ReturnType<typeof reduxStore.getState>; //Used in TSX, RootState is Alias. use to foresee propnames of use-state
//export type AppDispatch = typeof reduxStore.dispatch;

export default store